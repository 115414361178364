.questionText {
	font-weight: 700;
	transition: color 0.3s ease;
	font-size: 1.8rem;
	line-height: 1.3em;
	text-align: left;

	color: black;
	background: none;
}

.questionText::before {
	content: "\201C";
	color: lightgrey;
	padding-right: 0.3rem;
	margin-left: -0.7em;
}

.questionText::after {
	content: "\201D";
	color: lightgrey;
	padding-left: 0.3rem;
}

.questionNumber {
	display: block;
	width: 100%;
	text-align: center;
	opacity: 0.3;
}

.accountQuestion input[type="text"],
.accountQuestion input[type="password"] {
	font-style: normal;
	font-size: 1rem;
	line-height: 2rem;
}
.listOfQuestions {
	padding-bottom: 8rem;
}

.listOfQuestions .card {
	background-color: rgba(255, 255, 255, 1);
	background-color: var(--dkBrandColor);
}
.listOfQuestions .toDo .questionText,
.listOfQuestions div:first-child .questionText {
	color: white;
}

section.questions {
	padding-bottom: 15rem;
}
section.questions > div {
	width: 95%;
}
.listOfAccountQuestions {
	max-width: 400px;
	margin-bottom: 1rem;
}
.accountQuestion:first-child input {
	border-radius: 10px 10px 0 0;
}

.accountQuestion:last-child input {
	border-radius: 0 0 10px 10px;
}

.accountQuestion {
	margin: 2px auto;
}

.question,
.futureQuestion {
	padding: 6rem 0 10rem 0;
	min-width: 250px;
	/* max-width: 80%; */
	margin: 0 auto;
	/* text-align: center; */
	min-height: 120px;
}

.questionText {
	padding: 0 3rem;
}

.futureQuestion {
	visibility: hidden;
} /* this class is changed to when either the question has an answer (-> question answered), or its index === howManyAnswered (-> question toDo) */

.futureQuestion:first-child {
	visibility: initial;
} /* allows first question to be visible at start */

.likert {
	/* display: flex; */
	padding-top: 15px;
	/* align-items:flex-start */
	/* justify-content: space-evenly */
	width: 100%;
}

input[type="range" i] {
	margin: 0 10%;
	width: 80%;
}

.likertAnswerButton {
	/* flex-grow: 1;
	flex-basis: 16%; */
	display: inline-block;
	width: 20%;
	text-align: center;
}

.likertAnswerButton button {
	transition: color 0.2s; /* will fade to white */
	/* 	flex-grow: 1;
	flex-basis: 16%;
	display: flex;
	flex-direction: column;
	align-items: center; */
	width: 50%;
	position: relative;
	padding: 43px 0 0 0;
	border-radius: 0;
}
.likertAnswerButton button:focus {
	outline: none;
}

.likertAnswerButton button .checkmark {
	height: 2rem;
	width: 2rem;
	margin: 0.5em 0 0.8em 0;
	/* background-image: radial-gradient(circle, #1588C4 90%, white 99%); */
	/* background: white; */
	background: var(--highlightMain);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0.7rem;

	pointer-events: none;
	box-shadow: 0px 2px 3px rgb(58 80 156 / 33%);
	transition: box-shadow 0.2s;
	/* box-shadow: 
    0px -1px 2px rgba(58, 80, 156, 0.44),  */
}

.fakeLikert {
	display: flex;
	padding-top: 15px;
	align-items: flex-start;
	justify-content: space-between;
	/* justify-content: space-evenly; */
	width: 100%;
	text-align: center;
}
.likertFakeRadioSingleDiv,
.likertFakeRadioSingleDiv > div {
	/* display: inline-block; */
	/* text-align: center;
	width: 16%; */
	font-size: 1rem;
	line-height: 110%;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 2rem;
}
/* .likertFakeRadioSingleDiv:nth-child(odd) {
	background: lightgrey;
} */
.fakeCheckmark {
	height: 2rem;
	width: 2rem;
	margin: 0.5em 0 0.8em 0;
	background: var(--highlightMain);
	border-radius: 50%;
	pointer-events: none;
	box-shadow: 0px 2px 3px rgb(58 80 156 / 33%);
	/* transition: box-shadow 0.2s; */
}

.likertTags {
	padding-top: 1.5rem;
}

.likertTag {
	display: inline-block;
	margin: 0 0.1rem 0.3rem 0;
}

.likertTag button {
	padding: 1rem;
	box-shadow: 0px 2px 3px rgb(58 80 156 / 33%);
	border-radius: 2rem;
	margin-right: 0.2rem;
	background-color: white;
}

.likertTag button:focus {
	outline: none;
}

.slider {
	padding-top: 35px;
}

/* when answered... */
.answered .questionText {
	color: #aaa;
}

.answer {
	height: 2rem;
	color: white;
	padding-top: 1rem;
	font-size: 1.3rem;
	/* padding-left: 2em; */
	/* transition: padding-left 0.5s ease; */
}

.answer .prompt {
	font-size: 1rem;
	color: #bbb;
	/* padding-right: 2em; */
}

.answered .answer {
	font-weight: bold;
	padding-left: 0;
}

.answered .radioAndLabel input[type="radio"] {
	opacity: 0.3;
}

.answered .radioAndLabel {
	color: #fff; /* other option labels disappear... */
}

.answered .radioAndLabel input[type="radio"]:checked {
	opacity: 1;
}

.answered .radioAndLabel input[type="radio"]:checked + label {
	color: #fff; /* ...selected gets stronger, bigger */
	font-weight: bold;
	font-size: 12px;
}

input[type="text"],
input[type="password"] {
	padding: 10px;
	width: 100%;
}
