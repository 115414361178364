@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Lora:ital,wght@1,400;1,406&family=Playfair+Display:wght@400;900&display=swap");
:root {
	font-size: 12px; /* so 14px is 1.4rem */
	--font: "Karla", sans-serif; /*  var(--font)    */

	--highlight: #1588c4; /*  var(--highlight)    */
	--dkGrey: #1a1919; /*  var(--dkGrey)    */
	--midGrey: #afafaf; /*  var(--midGrey)  ???????????????  */
	--bgGrey: #eeeeee; /*  var(--bgGrey)    */
	--dkBrandColor: #000000; /*  var(--dkBrandColor)    */
	--bgDark: #000000; /*  var(--bgDark)    */
	--justice_warriors: hsla(4, 100%, 60%, 1);
	--global_stewards: hsla(201, 81%, 43%, 1);
	--innovation_leaders: hsla(28, 85%, 49%, 1);
	--preservers: hsla(132, 22%, 47%, 1);
	--shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.38);

	--rotateGraphFrom: 0deg; /*  var(--rotateGraphFrom)  */
	--rotateGraphTo: 120deg; /*  var(--rotateGraphTo)  */
}
@media only screen and (min-width: 900px) {
	:root {
		font-size: 12px;
	}
}

/* ==== RESET  ==== */

* {
	margin: 0;
	padding: 0;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* html {
	scroll-behavior: smooth;
} */

body,
textarea,
input,
button.big {
	font: normal 300 1.4rem/130% "Karla", sans-serif;
}
h1 {
	font: normal 400 3.2rem/117% "Playfair Display", serif;
}
h2,
h3,
h4,
.questionText {
	font: normal 900 1.8rem/130% "Playfair Display", serif;
}
a,
a:link,
a:visited,
a:active,
.link {
	text-decoration: none;
	border-bottom: 1px dotted currentColor;
	color: currentColor;
}
a.noUnderline {
	border-bottom: none;
}
i {
	font-family: "Lora", serif;
	font-style: italic;
}
button,
button.big,
.fakeBtnBig,
a.fakeBtnBig,
.fakeLikert {
	color: var(--highlight);
	font-family: var(--font);
	font-weight: bold;
}
button.big,
a.fakeBtnBig,
.fakeBtnBig {
	font-size: 1.8rem;
	font-family: "Playfair Display", serif;
	margin: 20px auto 5px auto;
	padding: 1rem 2rem; /* 1rem */
	/* width: 100%; */ /* 200px */
	max-width: 100%;
	min-width: 60%;
	border-radius: 8px;
	background-color: var(--bgGrey);
}
.fakeBtnBig {
	display: inline-block;
	/* display: block; */
	text-align: center;
}

button.big span,
a.fakeBtnBig span {
	color: var(--midGrey);
}
.grey button.big,
.grey a.fakeBtnBig {
	box-shadow: var(--shadow);
}

.small {
	font-size: 1.1rem;
}

.dark {
	color: white;
	background: var(--bgDark);
}

/* .dark a:link,
.dark a:visited {
	color: white;
	text-decoration: none;
} */
.grey {
	background: var(--bgGrey);
}
.grey button {
	background: white;
}

.highlight {
	color: var(--highlight);
}
.hint {
	color: var(--midGrey);
}

.footnote {
	margin-top: 3rem;
	color: var(--midGrey);
	font-size: 1.2rem;
}
.footnote hr {
	width: 20%;
	border-top: 2px solid var(--midGrey);
	margin-bottom: 0.7rem;
}

img {
	max-width: 100%;
}

section {
	width: 100%;
	padding-top: 33px;
}
.gap {
	padding-top: 33px;
}

section.last {
	padding-bottom: 33px;
}
section.footer {
	padding-bottom: 99px;
}
section.footer div.small {
	margin-bottom: 1rem;
	display: inline-block;
	margin-right: 2rem;
}

section > div {
	margin: 0 auto;
	width: 80%;
	max-width: 400px;
	border: none;
}

.tabs .selected {
	background: #ffffff;
	color: black; /* blue */
}
.tabs {
	padding-top: 30px;
}

.tab {
	font-size: 1.1rem;
	background: #ffffff44; /*  ?  */
	color: white;
	display: inline-block;
	padding: 3px 0;
	margin: 0;
	border-radius: 5px 5px 0 0;
	width: 25%;
	text-align: center;
}

h1 span,
h3 span {
	display: block;
	opacity: 0.5;
}
h4 span {
	opacity: 0.4;
}

h1,
p,
h3,
h4 {
	padding-bottom: 1.5rem;
}
h4 {
	padding-bottom: 0.8rem;
}

.noPadding {
	padding: 0;
}

.h5 {
	font-family: "Playfair Display", serif;
	font-weight: 900;
	font-style: normal;
	padding: 0;
}

.topnav {
	padding-top: 2rem;
}
.topnav > div {
	display: grid;
	grid-template-columns: 1.6rem auto 3rem 3rem;
	column-gap: 1rem;
}
.topnav_light_bg {
	background-image: url("../pageHtml/images/nnv/grey_texture_BG.png");
}
.print,
.share {
	justify-self: right;
}
.back div {
	outline: 1px solid red;
	height: 1.6rem;
	display: inline;
	font-size: 0.5rem;
}
.startNew {
	justify-self: left;
	font-size: 1rem;
}
.topnav img,
.topnav span {
	height: 1.6rem;
	width: auto;
	opacity: 0.5;
}
.topnav img:hover,
.topnav span:hover {
	opacity: 1;
}

input[type="text"],
textarea {
	padding: 1rem;
	width: 100%;
}
fieldset input {
	display: block;
	margin-top: 2px;
}
fieldset input:first-child {
	border-radius: 1rem 1rem 0 0;
}
fieldset input:last-child,
fieldset textarea:last-child {
	border-radius: 0 0 1rem 1rem;
}
textarea,
input.rounded {
	border-radius: 1rem;
	margin-top: 2px;
}

.tag {
	background-color: black;
	color: white;
	display: inline-block;
	border-radius: 2rem;
	padding: 0.3rem 1.2rem;
	margin: 1px;
}

blockquote {
	margin-top: 10px;
	padding: 15px;
	border-radius: 5px;
	background: var(--bgGrey);
}
blockquote::before {
	content: "\201C";
}

blockquote::after {
	content: "\201D";
}

.caps {
	text-transform: capitalize;
}

.example {
	position: relative;
	padding: 8rem 2.3rem 3rem;
	/* background-image: url('../images/examples-phone-BG.png'); */
	/* background-size: cover; */
	margin-bottom: 2rem;
}
.example {
	background: url("../pageHtml/images/nnv/examples-phone-BG.png");
	/* 	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover; */
	background-size: 100%; /* setting only one value defaults to width */
	background-repeat: no-repeat;
}
.example img {
	min-width: 100%;
}

ul.benefits {
	/* display: grid;
	grid-template-columns: auto auto; */
	margin-top: 3rem;
	padding-bottom: 2rem;
}

.benefits li {
	list-style: none;
	/* display: inline-block; */
	/* width: 49%; */
	/* height: 8rem; */
	background-image: url("../pageHtml/images/nnv/consultancy-benefit-ICON.png");
	background-repeat: no-repeat;
	background-size: 1.5rem;
	background-position: 35% left;
	padding-left: 3rem;
	margin-bottom: 2rem;
	color: var(--justice_warriors);
	/* text-align: center; */
}

.oddEven section:nth-child(even) {
	background: var(--bgGrey);
}

/********** NN GRAPHS *********/
/* .valuedLessMore_graph div {
	outline: 1px solid red;
}
.valuedLessMore_graph span {
	outline: 1px solid blue;
} */
/* .valuedLessMore_graph .graph_row {
	width: 100%;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1rem;
}
.valuedLessMore_graph .pos {
	margin-left: 50%;
	background-color: hsl(0, 0%, 30%);
	color: white;
}
.valuedLessMore_graph .neg {
	margin-right: 50%;
	background-color: lightGrey;
}
.neg,
.pos {
	padding: 1rem;
}
.neg {
	border-radius: 3rem 0 0 3rem;
}
.pos {
	border-radius: 0 3rem 3rem 0;
}
 */
/***************** .valuedMoreOrLess_graph *************************/

.valuedMoreOrLess_graph .number {
	opacity: 50%;
	padding: 0.8rem;
}
div.legend {
	width: 100%;
}
div.legend span {
	width: 49%;
	display: inline-block;
}
div.legend span.legendHalfRight {
	text-align: center;
}

.negHalf .bar,
.posHalf .bar {
	padding: 1rem;

	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.1rem;
}
.posHalf .bar {
	border-radius: 0 3rem 3rem 0;
	background-color: hsl(0, 0%, 30%);
	text-align: right;
	color: white;
	overflow: hidden;
}
.negHalf .bar {
	border-radius: 3rem 0 0 3rem;
	background-color: hsl(0, 0%, 90%);
	text-align: left;
	color: hsl(0, 0%, 30%);
}

/* to show bar labels even when they're too long... */
/* ... stack two copies exactly above each other */
.stackedGraphs {
	position: relative;
}
.over {
	position: absolute;
	width: 100%;
	top: 0;
}
/* clip the labels on the upper graph */
.posHalf .bar {
	overflow: hidden;
}
/* but show them on the graph poking out underneath */
.under .posHalf .bar {
	color: hsl(0, 0%, 60%);
	overflow: visible;
}

/************** scale diagram **************/

.scaleDiagram {
	position: relative;
	overflow: hidden;
	min-height: 50px;
	margin-bottom: 2rem;
}

.scaleBar,
.scaleMarker,
.scaleLegend,
.scaleLegendLow,
.scaleLegendHigh {
	position: absolute;
}
.scaleMarker {
	box-shadow: var(--shadow);
}

.scaleBar {
	width: 100%;
	height: 1px;
	background: black;
	top: 20px;
}

.scaleMarkerOuter {
	position: relative;
	left: 50%; /* TODO position replace with score % */
	top: 10px;
}

.scaleMarker {
	width: 22px;
	height: 22px;
	left: -10px; /* TODO position replace with score % */
	border-radius: 99px;
}

.scaleLegend {
	width: 100%;
	top: 30px;
}

.scaleLegendLow {
	left: 0;
}

.scaleLegendHigh {
	right: 0;
}

/********** QUESTION UI OVERRIDES *********/

.listOfQuestions .toDo .questionText,
.listOfQuestions div:first-child .questionText {
	color: initial;
}
section.questions h3,
.questionText {
	text-align: center;
}
section.questions > div {
	width: 92%;
}

.listOfQuestions .card {
	/* background-color: var(--bgGrey); */
	background-color: white;
}
.likertAnswerButton {
	width: 16%;
}
.answer {
	text-align: center;
}

.likertAnswerButton button {
	font-size: 1.1rem;
	background-color: white;
}
.answered .answer {
	color: var(--highlight);
	font-size: 1.5rem;
	text-align: center;
}
.answered button {
	color: white;
}
.answer .prompt {
	font-size: 1.2rem;
	display: none; /* /////// hidden //////// */
}
.answered .clicked .checkmark,
.clicked .fakeCheckmark {
	background-color: var(--highlight);
}
.answered .likertOptionLabel {
	color: white;
}
.question,
.futureQuestion {
	padding: 6rem 0 2rem 0;
}
.questions {
	text-align: center;
}
.futureQuestion {
	display: none;
}

/****************  archetypes *******************  */

.justice_warriors .dark,
.justice_warriors .scaleMarker {
	background-color: var(--justice_warriors);
}
.justice_warriors button,
.justice_warriors .scaleLegend,
.justice_warriors h3,
.justice_warriors h4,
.justice_warriors .yourArchetype b {
	color: var(--justice_warriors);
}
.justice_warriors .dark,
.justice_warriors .dark h4,
.justice_warriors .footer h3 {
	color: white;
}

.innovation_leaders .dark,
.innovation_leaders .scaleMarker {
	background-color: var(--innovation_leaders);
}
.innovation_leaders button,
.innovation_leaders .scaleLegend,
.innovation_leaders h3,
.innovation_leaders h4,
.innovation_leaders .yourArchetype b {
	color: var(--innovation_leaders);
}
.innovation_leaders .dark,
.innovation_leaders .dark h4,
.innovation_leaders .footer h3 {
	color: white;
}

.global_stewards .dark,
.global_stewards .scaleMarker {
	background-color: var(--global_stewards);
}
.global_stewards button,
.global_stewards .scaleLegend,
.global_stewards h3,
.global_stewards h4,
.global_stewards .yourArchetype b {
	color: var(--global_stewards);
}
.global_stewards .dark,
.global_stewards .dark h4,
.global_stewards .footer h3 {
	color: white;
}

.preservers .dark,
.preservers .scaleMarker {
	background-color: var(--preservers);
}
.preservers button,
.preservers .scaleLegend,
.preservers h3,
.preservers h4,
.preservers .yourArchetype b {
	color: var(--preservers);
}
.preservers .dark,
.preservers .dark h4,
.preservers .footer h3 {
	color: white;
}

/******************* report illus headers ********************/

/* indetail_illus_header_375 */
/* .reportInDetailHeader {
	background-image: url("../pageHtml/images/nnv/indetail_illus_header_375.png");
}
.reportactionHeader {
	background-image: url("../pageHtml/images/nnv/action_illus_header_375.png");
} */
.illusHeader {
	background-position: 50% 50%;
	position: relative;
	width: 100%;
}

.illusHeader img {
	margin: 0 auto;
	height: 17rem;
	margin-bottom: 3rem;
	/* position: relative;
	left: 30%; */
}
.illusHeader p {
	color: white;
}
.illusHeader h1 span {
	text-transform: capitalize;
}
.centred {
	width: 100%;
	text-align: center;
}
.extraIllus {
	position: relative;
	/* height: 1rem; */
}
.extraIllus img {
	position: absolute;
	bottom: 0.5rem;
	left: 0.2rem;
	width: 90%;
	height: auto;
}
.grey_texture .dark {
	background-image: url("../pageHtml/images/nnv/grey_texture_BG.png");
}
.home .dark {
	background-image: url("../pageHtml/images/nnv/black_texture_BG.png");
}

.justice_warriors .dark {
	background-image: url("../pageHtml/images/nnv/just_texture_BG.png");
}
.innovation_leaders .dark {
	background-image: url("../pageHtml/images/nnv/innov_texture_BG.png");
}
.preservers .dark {
	background-image: url("../pageHtml/images/nnv/pres_texture_BG.png");
}
.global_stewards .dark {
	background-image: url("../pageHtml/images/nnv/glob_texture_BG.png");
}

.home .illusHeader {
	background-image: url("../pageHtml/images/nnv/black_illusHeader_tall375.png");
	margin-bottom: -3rem;
	background-position: 50% 35%;
}
.global_stewards .illusHeader {
	background-image: url("../pageHtml/images/nnv/glob_indetail_illusHeader_tall375.png");
}
.justice_warriors .illusHeader {
	background-image: url("../pageHtml/images/nnv/just_indetail_illusHeader_tall375.png");
}
.innovation_leaders .illusHeader {
	background-image: url("../pageHtml/images/nnv/innov_indetail_illusHeader_tall375.png");
}
.preservers .illusHeader {
	background-image: url("../pageHtml/images/nnv/pres_indetail_illusHeader_tall375.png");
}

/* 
grey_texture_BG,
    glob_texture_BG,
    innov_texture_BG,
    pres_texture_BG,
    just_texture_BG,

	just_indetail_illusHeader_tall375,
    pres_indetail_illusHeader_tall375,
    innov_indetail_illusHeader_tall375,
    glob_indetail_illusHeader_tall375,
*/

/*****************  ******************/

.hide {
	opacity: 0;
}

img.bookCover {
	width: 100%;
}

.home h1 {
	margin-bottom: 7rem;
}

.dev {
	opacity: 0.05;
}
.dev:hover {
	opacity: 0.3;
}

/**************** PRINT *******************/
@media print {
	button.big,
	.fakeBtnBig,
	a.fakeBtnBig,
	form,
	.tabs,
	section.footer div.small {
		display: none;
	}

	.bar,
	.scaleBar,
	.scaleMarker {
		border: 1px solid grey;
	}
	.dark {
		color: black;
	}
}

/**************** SMALL SCREEN *******************/

@media only screen and (max-width: 600px) {
	.small {
		font-size: 1.4rem;
	}
	.answer .prompt {
		font-size: 1.4rem;
	}
}
@media only screen and (max-width: 350px) {
	.tab {
		font-size: 1rem;
	}
	.likertFakeRadioSingleDiv,
	.likertFakeRadioSingleDiv > div {
		/* display: inline-block; */
		font-size: 0.9rem;
		line-height: 110%;
		letter-spacing: -0.2px;
	}
}
